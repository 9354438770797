import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  Companies: any;
  Url = 'https://account.youco-it.com/';
  AppId = '461d1467-5ff7-41b1-b3be-5ed07e2885dd';
  constructor() {}

  getUser() {
    return !!localStorage.getItem('x-api-key');
  }

  login() {
    window.location.href = this.Url + 'application/' + this.AppId + '?logout=true';
  }
  logout() {
    localStorage.removeItem('x-api-key');
    window.location.reload();
  }
}
